import { RouteRecordRaw } from 'vue-router'

export const enum RouteName {
    Error404 = 'Error404',

    GridMemeTemplate = 'GridMemeTemplate',
    GridHologra = 'GridHologra',
    GridHolograVideo = 'GridHolograVideo',

    MemeEditor = 'MemeEditor',
    MemeEditorHologra = 'MemeEditorHologra',

    Login = 'Login',
    Admin = 'Admin',
    AdminNewTemplate = 'AdminNewTemplate',
    AdminEditTemplate = 'AdminEditTemplate',
}

export const enum RouteMetaKey {
    ShouldCache = 'ShouldCache',
    RequireAuth = 'RequireAuth',
}

export const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        component: () => import('@/web/client/layouts/MainLayout/MainLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/web/client/pages/Home/HomePage.vue'),
                meta: {
                    [RouteMetaKey.ShouldCache]: true,
                },
                children: [
                    {
                        name: RouteName.GridMemeTemplate,
                        path: '',
                        component: () => import('@/web/client/pages/Home/GridMemeTemplate.vue'),
                    },
                    {
                        path: 'hologra',
                        children: [
                            {
                                name: RouteName.GridHologra,
                                path: '',
                                component: () => import('@/web/client/pages/Home/GridHologra.vue'),
                            },
                            {
                                name: RouteName.GridHolograVideo,
                                path: ':ytbVideoId',
                                component: () => import('@/web/client/pages/Home/GridHolograVideo.vue'),
                                props: true,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'faq',
                component: () => import('@/web/client/pages/Faq/FaqPage.vue'),
                meta: {
                    [RouteMetaKey.ShouldCache]: true,
                },
            },
            {
                name: RouteName.MemeEditor,
                path: 'meme/:slug',
                component: () => import('@/web/client/pages/MemeEditor/EditorPageForMemeTemplate.vue'),
                props: true,
                meta: {
                    [RouteMetaKey.ShouldCache]: true,
                },
            },
            {
                name: RouteName.MemeEditorHologra,
                path: 'meme/:ytbVideoId/:frameIdx',
                component: () => import('@/web/client/pages/MemeEditor/EditorPageForHologra.vue'),
                props: true,
                meta: {
                    [RouteMetaKey.ShouldCache]: true,
                },
            },
            {
                name: RouteName.Login,
                path: 'login',
                component: () => import('@/web/client/pages/Login/LoginPage.vue'),
            },
            {
                name: RouteName.Admin,
                path: 'admin',
                redirect: '/admin/template',
                component: () => import('@/web/client/pages/Admin/AdminPage.vue'),
                meta: {
                    [RouteMetaKey.RequireAuth]: true,
                },
                children: [
                    {
                        path: 'template',
                        component: () => import('@/web/client/pages/Admin/ListMemeTemplate.vue'),
                    },
                    {
                        name: RouteName.AdminNewTemplate,
                        path: 'template/new',
                        component: () => import('@/web/client/pages/Admin/TemplateEditor/TemplateEditorCreate.vue'),
                    },
                    {
                        name: RouteName.AdminEditTemplate,
                        path: 'template/edit/:memeTemplateId',
                        component: () => import('@/web/client/pages/Admin/TemplateEditor/TemplateEditorEdit.vue'),
                        props: true,
                    },
                    {
                        path: 'hologra',
                        component: () => import('@/web/client/pages/Admin/ListHologra.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '',
        component: () => import('@/web/client/layouts/ErrorLayout/ErrorLayout.vue'),
        children: [
            {
                name: RouteName.Error404,
                path: '/:pathMatch(.*)*',
                component: () => import('@/web/client/pages/Error/404Page.vue'),
            },
        ],
    },
]
