import fuzzysort from 'fuzzysort'
import { defineStore } from 'pinia'
import { RouteLocationNormalized } from 'vue-router'
import { HydrationKey, loadStateFromWindow } from '../Hydration'
import { MAX_FETCH_ATTEMPTS, S3_SIZE_CARD, S3_SIZE_LARGE, S3_SIZE_THUMBNAIL } from '@/common/Constants'
import { MemeTemplateDefaultTextSetting } from '@/common/db/models/MemeTemplateDefaultTextSetting'
import { MemeTemplateGrowDirection } from '@/common/db/models/MemeTemplateGrowDirection'
import { assertion } from '@/common/utils/assertion'
import { deepFreeze } from '@/common/utils/deepFreeze'
import { getS3KeyHologra, getS3PublicUrlHologra, getS3PublicUrlMemeTemplateFile } from '@/common/utils/getS3'
import { slugify } from '@/common/utils/slugify'
import { AppContext } from '@/web/AppContext'
import { RouteName } from '@/web/client/router/routes'
import { fetchWithSsrProxy } from '@/web/client/utils/fetchWithSsrProxy'
import { getFirstMemeTemplateFileUrl } from '@/web/client/utils/getFirstMemeTemplateFileUrl'
import { parseRouteArg } from '@/web/client/utils/parseRouteArg'
import { HolograResponse } from '@/web/server/interfaces/Responses/HolograResponse'
import { MemeTemplateApproveResponse, MemeTemplateResponse } from '@/web/server/interfaces/Responses/MemeTemplateResponse'
import { PreparedOptionHologra } from './PreparedOptionHologra'
import { PreparedOptionMemeTemplate } from './PreparedOptionMemeTemplate'
import { PreparedOptionMemeTemplateFile } from './PreparedOptionMemeTemplateFile'
import { ErrorResponse } from '@/web/server/interfaces/Responses/ErrorResponse'
import { getNow } from '@/common/utils/getNow'
import { HololiveTalentResponse } from '@/web/server/interfaces/Responses/HololiveTalentResponse'
import { PreparedOptionHololiveTalent } from './PreparedOptionHololiveTalent'

// ----------------------------------------------------------------------------
// State
// ----------------------------------------------------------------------------

export type MemeTemplateState = {
    talentMap: Map<number, HololiveTalentResponse>
    memeTemplateMap: Map<number, MemeTemplateResponse>
    holograMap: Map<string, HolograResponse>
}

function createDefaultState(): MemeTemplateState {
    const defaultState: MemeTemplateState = {
        talentMap: new Map(),
        memeTemplateMap: new Map(),
        holograMap: new Map(),
    }

    return defaultState
}

// ----------------------------------------------------------------------------
// Store
// ----------------------------------------------------------------------------

export const useMemeTemplateStore = defineStore('MemeTemplateStore', {
    state: createDefaultState,

    getters: {
        talents: (state) => [...state.talentMap.values()],
        templates: (state) => [...state.memeTemplateMap.values()],
        hologras: (state) => [...state.holograMap.values()],

        approvedTemplates(): Array<MemeTemplateResponse> {
            return this.templates.filter((template) => Boolean(template.approvedAt))
        },

        preparedOptionsHololiveTalent(): Array<PreparedOptionHololiveTalent> {
            const items = new Array<PreparedOptionHololiveTalent>()

            for (let i = this.talents.length - 1; i >= 0; i--) {
                const { id, name } = this.talents[i]
                items.push({
                    id,
                    name,
                    namePrepared: fuzzysort.prepare(name),
                })
            }

            return items
        },

        preparedOptionsMemeTemplate(): Array<PreparedOptionMemeTemplate> {
            const items = new Array<PreparedOptionMemeTemplate>()

            for (const memeTemplate of this.approvedTemplates) {
                const name = memeTemplate.name
                const members = memeTemplate.talents.map((talent) => talent.hololiveTalent.name).join(', ')
                const iconUrl = getFirstMemeTemplateFileUrl(memeTemplate, S3_SIZE_THUMBNAIL)

                items.push({
                    iconUrl,
                    background: memeTemplate.defaultBgColor ?? 'none',
                    route: `/meme/${memeTemplate.slug}`,

                    name,
                    namePrepared: fuzzysort.prepare(name),

                    members,
                    membersPrepared: fuzzysort.prepare(members),
                })
            }

            for (const hologra of this.hologras) {
                const name = hologra.title

                items.push({
                    iconUrl: hologra.thumbnailUrl,
                    background: 'none',
                    route: `/hologra/${hologra.ytbVideoId}`,

                    name,
                    namePrepared: fuzzysort.prepare(name),
                })
            }

            return items
        },

        preparedOptionsMemeTemplateFile(): Array<PreparedOptionMemeTemplateFile> {
            const items = new Array<PreparedOptionMemeTemplateFile>()
            const filesAdded = new Set<string>() // Some templates have duplicate files (e.g. panik memes)

            for (const memeTemplate of this.approvedTemplates) {
                const members = memeTemplate.talents.map((talent) => talent.hololiveTalent.name).join(', ')

                for (const [idx, file] of memeTemplate.files.entries()) {
                    if (filesAdded.has(file.fileName)) {
                        continue
                    }

                    filesAdded.add(file.fileName)

                    const iconUrl = getS3PublicUrlMemeTemplateFile(file.fileName, S3_SIZE_CARD)
                    const objUrl = getS3PublicUrlMemeTemplateFile(file.fileName, S3_SIZE_LARGE)
                    const fileLabel = memeTemplate.name + (memeTemplate.files.length > 1 ? ` (${idx + 1})` : '')

                    items.push({
                        cardUrl: iconUrl,
                        objUrl,

                        fileLabel,
                        fileLabelPrepared: fuzzysort.prepare(fileLabel),

                        members,
                        membersPrepared: fuzzysort.prepare(members),
                    })
                }
            }

            return items
        },

        preparedOptionsHologra(): Array<PreparedOptionHologra> {
            const items = new Array<PreparedOptionHologra>()

            for (const hologra of this.hologras) {
                const name = hologra.title
                const frames: PreparedOptionHologra['frames'] = []

                for (let i = 1; i <= (hologra.numFrames ?? 0); i++) {
                    const cardUrl = getS3PublicUrlHologra(hologra.ytbVideoId, i, S3_SIZE_CARD)
                    const objUrl = getS3PublicUrlHologra(hologra.ytbVideoId, i, S3_SIZE_LARGE)
                    frames.push({ cardUrl, objUrl })
                }

                items.push({
                    iconUrl: hologra.thumbnailUrl,
                    publishedAt: hologra.publishedAt,
                    frames,

                    name,
                    namePrepared: fuzzysort.prepare(name),
                })
            }

            return items
        },
    },

    actions: {
        async init(appContext?: AppContext): Promise<void> {
            if (!DEFINE.IS_SSR) {
                const savedState = loadStateFromWindow(HydrationKey.MemeTemplateStore)
                if (savedState) {
                    this.$patch(savedState)
                    return
                }
            }

            const { data: talents } = await fetchWithSsrProxy<Array<HololiveTalentResponse>>('/api/talent', {}, appContext)
            this.talentMap = new Map(talents?.map((talent) => [
                talent.id,
                deepFreeze(talent),
            ]))

            const { data: templates } = await fetchWithSsrProxy<Array<MemeTemplateResponse>>('/api/template', {}, appContext)
            this.memeTemplateMap = new Map(templates?.map((template) => [
                template.id,
                deepFreeze(template),
            ]))

            const { data: hologras } = await fetchWithSsrProxy<Array<HolograResponse>>('/api/hologra', {}, appContext)
            this.holograMap = new Map(hologras?.map((hologra) => [
                hologra.ytbVideoId,
                deepFreeze(hologra),
            ]))
        },

        async refreshTemplate(memeTemplateId: string | number) {
            const result = await fetchWithSsrProxy<MemeTemplateResponse>(`/api/template/${memeTemplateId}`, {})

            if (result.data) {
                this.memeTemplateMap.set(result.data.id, deepFreeze(result.data))
            }

            return result
        },

        getRandomMemeRoute(currentRoute: RouteLocationNormalized): string {
            let path: string | null = null

            for (let i = 0; i < MAX_FETCH_ATTEMPTS; i++) {
                const i = Math.floor(Math.random() * this.templates.length)
                path = `/meme/${(this.templates[i].slug)}`

                if (path !== currentRoute.path) {
                    break
                }
            }

            assertion(path !== null, 'Failed to get random meme')
            return path
        },

        getNextMemeRoute(currentRoute: RouteLocationNormalized): string {
            let path: string

            if (currentRoute.name === RouteName.MemeEditor) {
                const currentIdx = this.templates.findIndex((template) => template.slug === currentRoute.params.slug)
                const nextIdx = (currentIdx + 1) % this.templates.length
                const nextMeme = this.templates[nextIdx]
                path = `/meme/${(nextMeme.slug)}`
            } else if (currentRoute.name === RouteName.MemeEditorHologra) {
                const currentVideoId = parseRouteArg(currentRoute, 'params', 'ytbVideoId') ?? ''
                const currentVideo = this.holograMap.get(currentVideoId) ?? this.hologras[0]

                const currentFrameIdx = parseInt(parseRouteArg(currentRoute, 'params', 'frameIdx') ?? '1')
                const nextFrameIdx = (currentFrameIdx >= (currentVideo.numFrames ?? 0))
                    ? 1
                    : currentFrameIdx + 1

                path = `/meme/${currentVideo.ytbVideoId}/${nextFrameIdx}`
            } else {
                const nextMeme = this.templates[0]
                path = `/meme/${(nextMeme.slug)}`
            }

            return path
        },

        getMemeTemplateFromSlug(slug: string): MemeTemplateResponse | undefined {
            for (const template of this.memeTemplateMap.values()) {
                if (template.slug === slug) {
                    return template
                }
            }

            return undefined
        },

        getMemeTemplateFromHolograFrame(ytbVideoId: string, frameIdx: string): MemeTemplateResponse | undefined {
            const video = this.holograMap.get(ytbVideoId)
            if (!video) {
                return undefined
            }

            const idx = parseInt(frameIdx)
            if (isNaN(idx) || idx < 1 || idx > (video.numFrames ?? 0)) {
                return undefined
            }

            const name = `${video.title} (${frameIdx})`
            const slug = slugify(name)

            return deepFreeze({
                id: 0xDEADBEEF,
                name,
                slug,
                createdAt: video.publishedAt,
                approvedAt: video.publishedAt,
                isVisible: true,

                autoTextSlot: null,
                growDirection: MemeTemplateGrowDirection.Vertical,
                disableInnerBorders: true,
                defaultBgColor: null,
                defaultTextSettingFlags: MemeTemplateDefaultTextSetting.WhiteFillBlackStroke,

                files: [
                    {
                        id: 0xDEADBEEF,
                        fileName: getS3KeyHologra(ytbVideoId, frameIdx),
                        isFront: false,
                        flipX: false,
                        flipY: false,

                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                ],
                sources: [
                    {
                        id: 0xDEADBEEF,
                        url: `https://www.youtube.com/watch?v=${ytbVideoId}`,
                        username: null,
                    },
                ],
                textSlots: [],
                talents: [],
            })
        },

        async approveTemplates(templates: Array<{ id: number }>): Promise<ErrorResponse | null> {
            for (const { id } of templates) {
                const template = this.memeTemplateMap.get(id)
                if (!template) {
                    continue
                }

                const isApproved = Boolean(template.approvedAt)
                if (isApproved) {
                    continue
                }

                const { error } = await fetchWithSsrProxy<MemeTemplateApproveResponse>(`/api/template/${id}/approve`, { method: 'PUT' })
                if (error) {
                    return error
                }

                // WARN: Cannot deep freeze already frozen obj
                this.memeTemplateMap.set(id, {
                    ...template,
                    approvedAt: getNow(), // Will be slightly desynced from server but doesn't matter as long as it's not null
                })
            }

            return null
        },

        async unapproveTemplates(templates: Array<{ id: number }>): Promise<ErrorResponse | null> {
            for (const { id } of templates) {
                const template = this.memeTemplateMap.get(id)
                if (!template) {
                    continue
                }

                const isApproved = Boolean(template.approvedAt)
                if (!isApproved) {
                    continue
                }

                const { error } = await fetchWithSsrProxy<MemeTemplateApproveResponse>(`/api/template/${id}/approve`, { method: 'DELETE' })
                if (error) {
                    return error
                }

                // WARN: Cannot deep freeze already frozen obj
                this.memeTemplateMap.set(id, {
                    ...template,
                    approvedAt: null,
                })
            }

            return null
        },

        async deleteTemplates(templates: Array<{ id: number }>): Promise<ErrorResponse | null> {
            for (const { id } of templates) {
                const { error } = await fetchWithSsrProxy<MemeTemplateApproveResponse>(`/api/template/${id}`, { method: 'DELETE' })
                if (error) {
                    return error
                }

                this.memeTemplateMap.delete(id)
            }

            return null
        },
    },
})
