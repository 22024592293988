import { getS3PublicUrlMemeTemplateFile } from '@/common/utils/getS3'
import { MemeTemplateResponse } from '@/web/server/interfaces/Responses/MemeTemplateResponse'

export function getFirstMemeTemplateFileUrl(memeTemplate: MemeTemplateResponse, size?: number): string {
    if (memeTemplate.files.length === 0) {
        return ''
    }

    const templateFile = memeTemplate.files.find((file) => !file.isFront) ?? memeTemplate.files[0]
    return getS3PublicUrlMemeTemplateFile(templateFile.fileName, size)
}
