import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Suspense as _Suspense, withCtx as _withCtx, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      Component
        ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}