import { QSkeleton as _component_q_skeleton } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["datetime", "title"]

import { computed } from 'vue'
import { getRelativeTime } from '@/common/utils/getRelativeTime'


export default /*@__PURE__*/_defineComponent({
  __name: 'RelativeTime',
  props: {
    datetime: {},
    prefix: {},
    suffix: {}
  },
  setup(__props: any) {

const props = __props

const displayText = computed<string>(() => `${props.prefix ?? ''} ${getRelativeTime(props.datetime)} ${props.suffix ?? ''}`)

return (_ctx: any,_cache: any) => {
  
  const _component_ClientOnly = _resolveComponent("ClientOnly")!

  return (_ctx.datetime)
    ? (_openBlock(), _createElementBlock("time", {
        key: 0,
        datetime: _ctx.datetime,
        title: _ctx.datetime
      }, [
        _createVNode(_component_ClientOnly, null, {
          placeholder: _withCtx(() => [
            _createVNode(_component_q_skeleton, {
              type: "rect",
              width: "100%",
              height: "1em"
            })
          ]),
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(displayText.value) + " ", 1)
          ]),
          _: 1
        })
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})