import { defineStore } from 'pinia'
import { UserResponse } from '@/web/server/interfaces/Responses/UserResponse'
import { AppContext } from '@/web/AppContext'
import { fetchWithSsrProxy } from '@/web/client/utils/fetchWithSsrProxy'
import { loadStateFromWindow, HydrationKey } from '../Hydration'

// ----------------------------------------------------------------------------
// State
// ----------------------------------------------------------------------------

export type UserState = {
    user: UserResponse | null
}

function createDefaultState(): UserState {
    const defaultState: UserState = {
        user: null,
    }

    return defaultState
}

// ----------------------------------------------------------------------------
// Store
// ----------------------------------------------------------------------------

export const useUserStore = defineStore('UserStore', {
    state: createDefaultState,

    getters: {
        isLoggedIn: (state) => state.user !== null,
    },

    actions: {
        async init(appContext?: AppContext): Promise<void> {
            if (this.user) {
                return
            }

            if (!DEFINE.IS_SSR) {
                const savedState = loadStateFromWindow(HydrationKey.UserStore)
                if (savedState) {
                    this.$patch(savedState)
                    return
                }
            }

            const { data } = await fetchWithSsrProxy<UserResponse>('/api/auth', {}, appContext)
            this.user = data
        },

        async login(username: string, password: string) {
            const res = await fetchWithSsrProxy<UserResponse>('/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password,
                }),
            })

            this.user = res.data
            return res
        },

        async logout() {
            const res = await fetchWithSsrProxy<undefined>('/api/auth/logout', { method: 'POST' })
            this.user = null
            return res
        },
    },
})
