import { createMemoryHistory, createRouter, createWebHistory, Router } from 'vue-router'
import { useMemeTemplateStore } from '../store/MemeTemplate/useMemeTemplateStore'
import { parseRouteArg } from '../utils/parseRouteArg'
import { AppContext } from '@/web/AppContext'
import { RouteMetaKey, RouteName, routes } from './routes'
import { computed, watch } from 'vue'
import { useUserStore } from '../store/User/useUserStore'

// ----------------------------------------------------------------------------
// Router
// ----------------------------------------------------------------------------

export async function createVueRouter(appContext?: AppContext): Promise<Router> {
    const router = createRouter({
        history: DEFINE.IS_SSR
            ? createMemoryHistory()
            : createWebHistory(),

        routes,

        scrollBehavior(to, from, savedPosition) {
            if (to.hash) {
                return { el: to.hash }
            }

            if (savedPosition) {
                return savedPosition
            }

            if (to.path !== from.path) {
                return { top: 0 }
            }

            return undefined
        },
    })

    const memeTemplateStore = useMemeTemplateStore(appContext?.pinia)
    const userStore = useUserStore(appContext?.pinia)

    const isLoggedIn = computed(() => userStore.isLoggedIn)
    watch(isLoggedIn, async() => {
        if (router.currentRoute.value.meta[RouteMetaKey.RequireAuth] && !isLoggedIn.value) {
            console.warn(`[403] Cannot stay on ${router.currentRoute.value.fullPath} because isLoggedIn:${isLoggedIn.value}`)
            await router.push({ name: RouteName.Login })
        }
    })

    router.beforeEach((to, from, next) => {
        if (to.meta[RouteMetaKey.RequireAuth] && !isLoggedIn.value) {
            console.warn(`[403] Cannot navigate to ${to.fullPath} because isLoggedIn:${isLoggedIn.value}`)
            next({ name: RouteName.Login })
            return
        }

        next()
    })

    router.beforeEach((to, from, next) => {
        const next404 = () => {
            next({
                name: RouteName.Error404,
                params: {
                    // Preserve current path and remove the first char to avoid the target URL starting with '//'
                    pathMatch: to.path.substring(1).split('/'),
                },
                query: to.query,
                hash: to.hash,
            })
        }

        if (to.name === RouteName.MemeEditor) {
            const slug = parseRouteArg(to, 'params', 'slug') ?? ''
            const memeTemplate = memeTemplateStore.getMemeTemplateFromSlug(slug)
            if (!memeTemplate) {
                console.warn(`[404] slug:${slug} does not exist`)
                next404()
                return
            }
        }

        if (to.name === RouteName.MemeEditorHologra) {
            const ytbVideoId = parseRouteArg(to, 'params', 'ytbVideoId') ?? ''
            const frameIdx = parseRouteArg(to, 'params', 'frameIdx') ?? ''
            const memeTemplate = memeTemplateStore.getMemeTemplateFromHolograFrame(ytbVideoId, frameIdx)
            if (!memeTemplate) {
                console.warn(`[404] ytbVideoId:${ytbVideoId} frameIdx:${frameIdx} does not exist`)
                next404()
                return
            }
        }

        if (to.name === RouteName.GridHolograVideo) {
            const ytbVideoId = parseRouteArg(to, 'params', 'ytbVideoId') ?? ''
            const hologra = memeTemplateStore.holograMap.get(ytbVideoId)
            if (!hologra) {
                console.warn(`[404] ytbVideoId:${ytbVideoId} does not exist`)
                next404()
                return
            }
        }

        next()
    })

    if (appContext?.url) {
        await router.push(appContext.url)
    }

    return router
}
