import { S3_PUBLIC_URL } from '../Constants'
import { parseFilePath } from './parseFilePath'

// ----------------------------------------------------------------------------
// Prefix e.g. "prefix"
// ----------------------------------------------------------------------------

export const getS3PrefixHologra = (ytbVideoId: string) => {
    return `hologra/${ytbVideoId}/`
}

export const getS3PrefixMemeTemplateFile = (size?: number) => {
    return size === undefined
        ? 'template/original/'
        : `template/${size}/`
}

// ----------------------------------------------------------------------------
// Key e.g. "prefix/image.jpg"
// ----------------------------------------------------------------------------

export const getS3KeyHologra = (ytbVideoId: string, idx: number | string, size?: number) => {
    const prefix = getS3PrefixHologra(ytbVideoId)
    const name = idx.toString().padStart(4, '0')

    if (size === undefined) {
        return prefix + `${name}.jpg` // ffmpeg configured to always output jpg
    } else {
        return prefix + `${name}-${size}.webp`
    }
}

export const getS3KeyMemeTemplateFile = (fileName: string, size?: number) => {
    const prefix = getS3PrefixMemeTemplateFile(size)
    const { name } = parseFilePath(fileName)

    if (size === undefined) {
        return prefix + fileName
    } else {
        return prefix + `${name}-${size}.webp`
    }
}

// ----------------------------------------------------------------------------
// Public Url e.g. "https://cdn.holomemes.moe/prefix/image.jpg"
// ----------------------------------------------------------------------------

export const getS3PublicUrlHologra = (ytbVideoId: string, idx: number | string, size?: number) => {
    return `${S3_PUBLIC_URL}/${getS3KeyHologra(ytbVideoId, idx, size)}`
}

export const getS3PublicUrlMemeTemplateFile = (fileName: string, size?: number) => {
    // Special case when meme templates are created based on hologra screencaps
    if (fileName.startsWith('hologra/')) {
        const matches = /^hologra\/([\w-]+)\/(\d{4}).jpg$/.exec(fileName)
        if (!matches) {
            throw new Error(`Failed to parse ${fileName}`)
        }

        return getS3PublicUrlHologra(matches[1], matches[2], size)
    } else {
        return `${S3_PUBLIC_URL}/${getS3KeyMemeTemplateFile(fileName, size)}`
    }
}
