import type Konva from 'konva'

// ----------------------------------------------------------------------------
// General
// ----------------------------------------------------------------------------

export const APP_NAME = 'HoloMemes'
export const APP_TITLE = 'Hololive Meme Templates'
export const APP_DESC = 'Create Hololive memes or download meme templates from the Hololive subreddit'
export const APP_THEME_COLOR = '#46c2f2'

export const SENTRY_DSN = 'https://5bb3856d708149e4a231da5bcb0b6b33@o504161.ingest.sentry.io/5693392'

export const FETCH_TIMEOUT = 30 * 1000 // in ms
export const MAX_FETCH_ATTEMPTS = 3

// ----------------------------------------------------------------------------
// S3
// ----------------------------------------------------------------------------

export const S3_PUBLIC_URL = 'https://cdn.holomemes.moe'

export const S3_SIZE_THUMBNAIL = 100
export const S3_SIZE_CARD = 400
export const S3_SIZE_LARGE = 1000
export const S3_MEME_SIZES = [undefined, S3_SIZE_THUMBNAIL, S3_SIZE_CARD, S3_SIZE_LARGE]

// ----------------------------------------------------------------------------
// scrapeVideo
// ----------------------------------------------------------------------------

export const VIDEO_EXT = 'mp4'
export const YOUTUBE_DL_BIN = 'yt-dlp'
export const FFMPEG_BIN = 'ffmpeg'
export const FFPROBE_BIN = 'ffprobe'

export const HOLOGRA_PLAYLIST_ID = 'PL1NeGg1woXqngQytLzL8lJJLYwmzk1Wuq'
export const HOLOLIVE_VIDEOS_PLAYLIST_ID = 'PLzsu3LAt0sqABOEaOIynEyR5V90up3i5r'

// ----------------------------------------------------------------------------
// Backend
// ----------------------------------------------------------------------------

export const USER_AGENT = 'HoloMemes (by @trinovantes)'

export const COOKIE_DURATION = 30 * 24 * 3600 * 1000 // ms
export const ENCRYPTION_ALGORITHM = 'aes-256-gcm'
export const ENCRYPTION_KEY_LENGTH = 32 // bytes
export const ENCRYPTION_IV_LENGTH = 12 // bytes
export const ENCRYPTION_AUTH_TAG_LENGTH = 16 // bytes

export const DB_FILE = './db/live/holomemes.sqlite3'
export const DB_MEMORY = ':memory:'
export const MAX_MIGRATION_ATTEMPTS = 5

export const REDIS_PREFIX_SESSION = 'session:'
export const REDIS_PREFIX_PAGE = 'page:'
export const SITEMAP_CACHE_TIME = 7 * 24 * 3600 // secs
export const PAGE_CACHE_TIME = 3600 // secs

// ----------------------------------------------------------------------------
// Frontend
// ----------------------------------------------------------------------------

export const SEARCH_THRESHOLD = -100
export const NUM_MEMES_PER_PAGE = 24

export const MEME_CUSTOM_ELEMENT_SCALE = 0.8 // Initialized to ratio of stage width
export const MEME_CUSTOM_TEXT_HEIGHT = 100
export const MEME_HEADING_FONT_SIZE = 72
export const MEME_BODY_FONT_SIZE = 60
export const MEME_MIN_TEXT_STROKE = 2

export const MEME_MAX_WIDTH = 1000
export const MEME_MAX_HEIGHT = 2000
export const MEME_PADDING = 16 * 4
export const MEME_BORDER_WIDTH = 10
export const MEME_BACKGROUND_COLOR = 'rgba(255, 255, 255, 1)'

// ----------------------------------------------------------------------------
// Konva Constants
// ----------------------------------------------------------------------------

export const GHOST_BOX_KONVA_CONFIG: Konva.RectConfig = {
    listening: false,
    stroke: '#46c2f2',
    strokeWidth: 5,
    visible: false,
    strokeScaleEnabled: false,
}

export const BOUNDING_BOX_KONVA_CONFIG: Parameters<Konva.Shape['getClientRect']>[0] = {
    skipShadow: true,
    skipStroke: true,
    skipTransform: true,
}

export const SELECTED_TRANSFORMER_KONVA_CONFIG: Konva.TransformerConfig = {
    anchorStroke: 'rgba(0, 0, 0, 0.8)',
    anchorFill: 'rgba(255, 255, 255, 0.8)',
    anchorSize: 15,
    borderStroke: '#46c2f2',
    borderStrokeWidth: 5,
    keepRatio: false,
    ignoreStroke: true,
}

export const DEFAULT_TEXT_KONVA_CONFIG: Konva.TextConfig = {
    listening: false,
    verticalAlign: 'middle',
    fillAfterStrokeEnabled: true,
    shadowBlur: 10,
}
