import mitt from 'mitt'
import { Plugin, inject } from 'vue'

export enum AppEvent {
    DOWNLOAD_CANVAS = 'DOWNLOAD_CANVAS',
}

const APP_EVENT_EMITTER_KEY = Symbol('AppEvent')

export function useAppEvent(): ReturnType<typeof mitt> {
    return inject(APP_EVENT_EMITTER_KEY) as ReturnType<typeof mitt>
}

export const appEventPlugin: Plugin = {
    install(app) {
        app.provide(APP_EVENT_EMITTER_KEY, mitt())
    },
}
