export enum MemeTemplateDefaultTextSetting {
    WhiteFillBlackStroke = 1 << 0,
    BlackFillWhiteStroke = 1 << 1,
    DisableStroke = 1 << 2,
    DisableShadow = 1 << 3,
}

export function convertTextSettingFlagsToArray(flags: number) {
    const settings = new Array<MemeTemplateDefaultTextSetting>()
    const bits = [
        MemeTemplateDefaultTextSetting.WhiteFillBlackStroke,
        MemeTemplateDefaultTextSetting.BlackFillWhiteStroke,
        MemeTemplateDefaultTextSetting.DisableStroke,
        MemeTemplateDefaultTextSetting.DisableShadow,
    ]

    for (const bit of bits) {
        if (flags & bit) {
            settings.push(bit)
        }
    }

    return settings
}

export function convertTextSettingArrayToFlags(settings: Array<MemeTemplateDefaultTextSetting>) {
    let flags = 0

    for (const setting of settings) {
        flags |= setting
    }

    return flags
}
