export function parseFilePath(path: string): { name: string; extension: string; mimeType: string } {
    const matches = /([. _\-\w]+)\.(\w+)$/.exec(path)
    if (!matches) {
        throw new Error(`Failed to parse ${path}`)
    }

    const name = matches[1]
    const extension = matches[2].toLowerCase()

    let mimeType: string
    switch (extension) {
        case 'png':
            mimeType = 'image/png'
            break

        case 'jpg':
        case 'jpeg':
            mimeType = 'image/jpeg'
            break

        case 'webp':
            mimeType = 'image/webp'
            break

        case 'ts':
            mimeType = 'text/plain'
            break

        default:
            throw new Error(`Unknown file extension "${extension}"`)
    }

    return {
        name,
        extension,
        mimeType,
    }
}
