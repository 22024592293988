import { createPinia } from 'pinia'
import {Dialog as Dialog} from 'quasar/src/index.dev.js';
import {Quasar as Quasar} from 'quasar/src/index.dev.js';
import {QuasarPluginOptions as QuasarPluginOptions} from 'quasar/src/index.dev.js';
import {Notify as Notify} from 'quasar/src/index.dev.js';

import { createSSRApp } from 'vue'
import { AppContext } from './AppContext'
import AppLoader from './client/AppLoader.vue'
import ClientOnly from './client/components/ClientOnly.vue'
import ExternalLink from './client/components/ExternalLink.vue'
import RelativeTime from './client/components/RelativeTime.vue'
import { createVueRouter } from './client/router/createVueRouter'
import { useMemeTemplateStore } from './client/store/MemeTemplate/useMemeTemplateStore'
import { appEventPlugin } from './client/utils/useAppEvent'
import { useUserStore } from './client/store/User/useUserStore'
import { createHead } from '@unhead/vue'
import type { createRouter } from 'vue-router'

type VueApp = {
    app: ReturnType<typeof createSSRApp>
    router: ReturnType<typeof createRouter>
    head: ReturnType<typeof createHead>
}

export async function createVueApp(appContext?: AppContext): Promise<VueApp> {
    // Vue
    const app = createSSRApp(AppLoader)
    app.component('ClientOnly', ClientOnly)
    app.component('ExternalLink', ExternalLink)
    app.component('RelativeTime', RelativeTime)

    // Pinia
    const pinia = createPinia()
    app.use(pinia)

    if (appContext) {
        appContext.pinia = pinia
    }

    // Pinia Stores
    const memeTemplateStore = useMemeTemplateStore(pinia)
    await memeTemplateStore.init(appContext)

    const userStore = useUserStore(pinia)
    await userStore.init(appContext)

    // Vue Router
    const router = await createVueRouter(appContext)
    app.use(router)
    await router.isReady()

    // Unhead
    const head = createHead()
    app.use(head)

    // Quasar
    app.use<[Partial<QuasarPluginOptions>, AppContext?]>(Quasar, {
        plugins: {
            Dialog,
            Notify,
        },
    }, appContext)

    // AppEvent
    app.use(appEventPlugin)

    return {
        app,
        router,
        head,
    }
}
